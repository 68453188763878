<template>
  <div class="relative">
    <div class="content">
      <a
        v-show="!$store.state.isPartnerAccess"
        href="#"
        @click.prevent="$router.push('/tomador/painel')"
        class="self-start text-sm text-gray-800 underline">Voltar
      </a>
      <div class="mb-6">
        <div class="flex w-full justify-center">
          <profile-stepper class="w-full" :step="$store.state.business.step"/>
        </div>
      </div>
      <transition name="fade">
        <router-view @updateBusiness="$emit('updateBusiness')"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import ProfileStepper from '@/components/ProfileStepper/ProfileStepper'

export default {
  components: { ProfileStepper }
}
</script>

<style lang="sass" scoped>
.main
  @apply w-full

.content
  @apply w-full flex flex-col items-center bg-white p-6 mb-8 border shadow rounded-lg

.title
  @apply font-medium text-xl text-black text-center uppercase

.modal
  @apply w-full min-h-screen absolute top-0 z-50 flex flex-col items-center justify-center
  background-color: #F5F5F5

.close
  @apply absolute right-0 top-0 opacity-75 rounded-full mr-48 mt-4 cursor-pointer

.card
  @apply bg-white px-4 mb-4 rounded-lg border shadow

</style>
